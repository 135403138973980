﻿/* ===========================
File Name:      _image.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/14/2021
Produced By:    chris
=========================== */

////////////////////////////////////
// Background Image Chunk

div.basic-background-image {
  //Super basic setup for this component
  background-repeat: no-repeat;
  &.image-contain {
    background-size: contain;
  }
  &.image-cover {
    background-size: cover;
  }
  &.position-center-top {
    background-position: center top;
  }
  &.position-center-center {
    background-position: center center;
  }
  
  //Some media stuff for minimum height
  &.bg-image-small
  {
    min-height: 200px;
  }
  
  &.bg-image-big
  {
    min-height: 300px;
  }

  @include media-breakpoint-up(sm) 
  {
    &.bg-image-small
    {
      min-height: 250px;
    }

    &.bg-image-big
    {
      min-height: 350px;
    }
  }
  @include media-breakpoint-up(lg)
  {
    &.bg-image-small
    {
      min-height: 300px;
    }

    &.bg-image-big
    {
      min-height: 400px;
    }
  }
}
