﻿$mobileBreakpoint: 767px;

$breakpoint-xxl: 1400px;
$breakpoint-xl: 1200px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;

$accordion-bg: transparent;
$accordion-border-color: $versiti-ct-greyd;
$accordion-border-width: 1px;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: $versiti-ct-greyd;
$accordion-button-active-color: $versiti-ct-grey2;
$accordion-icon-color: $versiti-ct-magenta;
$accordion-icon-active-color: $versiti-ct-grey3;
$menuHeight: 88px;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 4;

$spacer: 1rem;
$spacers: ( 
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.9,
    5: $spacer * 3, );

@mixin bariol {
    font-family: Bariol, "Bariol", sans-serif;
}