﻿/* ===========================
File Name:      default.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/2/2021
Produced By:    chris
=========================== */

// The main container
main.clinicaltrials-defaultpagetype {
  
}