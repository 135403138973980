﻿@include bariol;

.hero-banner-component {
    .carousel-item {
        .hero-container {
            position: relative;

            .hero-image {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                height: 0;
                width: 100%;
                position: relative;
            }

            .hero-content {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                padding-right: 25%;

                .hero-title {
                    color: #fff;
                    font-size: 48px;
                    line-height: 1;
                }

                .hero-copy {
                    font-size: 32px;
                    line-height: 1.3;
                }
            }

            @media(max-width: 2000px) {
                .hero-content {
                    top: 34%;
                    left: 38%;
                    padding-right: 18%;
                }
            }

            @media(max-width: $breakpoint-xxl) {
                .hero-content {
                    top: 37%;
                    left: 36%;
                    padding-right: 18%;
                }
            }

            @media(max-width: $breakpoint-xl) {
                .hero-content {
                    top: 37%;
                    left: 0;
                    transform: translate(0, -50%);
                    padding-left: 3rem;
                    padding-right: 43%;

                    .hero-title {
                        font-size: 42px;
                    }

                    .hero-copy {
                        font-size: 26px;
                    }
                }
            }

            @media(max-width: $breakpoint-lg) {
                .hero-content {
                    top: 33%;

                    .hero-title {
                        font-size: 32px;
                    }

                    .hero-copy {
                        font-size: 22px;
                    }
                }
            }

            @media(max-width: $breakpoint-md) {
                .hero-content {
                    padding-right: 28%;

                    .hero-copy {
                        font-size: 20px;
                    }
                }
            }

            @media(max-width: $breakpoint-sm) {
                .hero-image {
                    background-color: $color-silver-chalice-grey;
                }

                .hero-content {
                    max-width: 100%;
                    padding: 0 1rem 2rem 1rem;
                    position: relative;
                    transform: none;
                    background-color: $color-silver-chalice-grey;
                    margin: 0 !important;

                    .hero-title {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 10%;
    }
}

.media-copy-component {
    margin: 1rem auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;

    &.media-first {
        .media-copy-media {
            order: 1;
        }

        .media-copy-content {
            order: 2;
            padding-left: 3rem;
        }
    }

    &.media-second {
        .media-copy-media {
            order: 2;
        }

        .media-copy-content {
            order: 1;
            padding-right: 3rem;
        }
    }

    .media-copy-media {
        > img {
            width: 100%;
            height: auto;
        }

        > iframe {
            width: 100%;
        }
    }

    .media-copy-content {
        .main-copy {
            font-size: 36px;
            color: $color-tango-orange;
            line-height: 1.1;
            display: block;
            text-align: left;
            margin: 1rem auto;
        }

        .sub-copy {
            color: $color-mine-shaft-grey;
            font-size: 22px;
            margin: 1rem auto;
            display: block;
            text-align: left;
        }
    }

    @media(max-width: $breakpoint-sm) {
        padding: 0;

        .media-copy-media {
            order: 2 !important;
            margin: 0;
            padding: 0;
        }

        .media-copy-content {
            order: 1 !important;
            padding: 0 2rem !important;

            .main-copy {
                font-size: 30px;
                line-height: 1.2;
            }
        }
    }
}

.highlight-content-component {
    padding: 0 2rem;
    position: relative;
    margin-top: 5rem;

    .highlight-media {
        position: absolute;
        top: -95px;
        right: -60px;

        > img {
            max-width: 682px;
        }
    }

    .highlight-content {
        color: $color-mine-shaft-grey;

        .main-copy {
            font-size: 30px;
            line-height: 1.2;
            display: block;
            text-align: left;
            margin: 1rem auto;
        }

        .sub-copy {
            font-size: 22px;
            line-height: 1.2;
            margin: 1rem auto;
            display: block;
            text-align: left;
        }
    }

    @media(max-width: $breakpoint-sm) {
        .highlight-content {
            padding: 0;
        }

        .highlight-media {
            padding: 0;
            position: inherit;
            min-height: 230px;

            > img {
                max-width: 400px;
                position: absolute;
                transform: translate(-25px, 0);
            }
        }
    }
}

.case-study-component {
    .hero-container {
        position: relative;

        .hero-image {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 0;
            width: 100%;
            position: relative;
            z-index: -1;
        }

        .hero-content {
            position: absolute;
            top: 20%;
            color: $color-mine-shaft-grey;

            .type-header {
                font-weight: 600;
            }

            .hero-title {
                font-size: 48px;
                line-height: 1;
            }

            .hero-copy {
                font-size: 32px;
                line-height: 1;
            }
        }
    }

    @media(max-width: $breakpoint-sm) {
        padding: 0;

        .hero-container {
            .hero-content {
                position: relative;

                .hero-title {
                    font-size: 30px;
                    line-height: 1.2;
                }

                .hero-copy {
                    font-size: 22px;
                    line-height: 1.2;
                }
            }
        }
    }
}

.card-list-component {
    margin-top: 7rem;

    .card-component {
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;

        .card-image {
            height: 180px;
            overflow: hidden;

            img {
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
            }
        }

        .card-text {
            flex: 1 1 auto;
            padding: 1rem;

            .headline {
                font-size: 28px;
                font-weight: 600;
                color: $heading-color;
            }

            .main-copy {
                color: $color-mine-shaft-grey;
                font-size: 16px;
                margin-top: .25rem;
                margin-bottom: 0;
                display: block;
                text-align: left;
                flex-grow: 1;
            }
        }
    }

    @media(max-width: $breakpoint-sm) {
        .card-component {
            .card-text {
                .main-copy {
                    font-size: 22px;
                    line-height: 1.2;
                }
            }
        }
    }
}

.prefooter-cta-component {
    text-align: center;
    background-color: $redesign-primary;
    color: #fff;

    .cta-content {
        padding: 2.75rem 0;

        .cta-headline {
            font-size: 34px;
            font-weight: 600;
        }
    }

    @media(max-width: $breakpoint-sm) {
        .cta-content {
            .cta-headline {
                font-size: 28px;
                line-height: 1.2;
            }
        }
    }
}

.main-landing-page {
    .landing-background {
        position: relative;

        .landing-page-bg {
            max-width: 1300px;
            position: absolute;
            z-index: -1;
        }

        .bg-top {
            top: 50%;
            left: 50%;
            transform: translate(-86%, -50%)
        }

        .bg-bottom {
            bottom: 0;
            right: 50%;
            transform: translate(35%, -8%)
        }
    }

    .btn-primary {
        background-color: #fff;
        border: none;
        border-radius: .5rem;
        font-size: 25px;
        font-weight: 600;
        color: $color-mine-shaft-grey;
    }

    .btn-outline-primary {
        background-color: $color-wild-sand-grey;
        border: 3px solid $redesign-primary;
        border-radius: .5rem;
        font-size: 25px;
        font-weight: 600;
        color: $color-mine-shaft-grey;
    }

    .btn-outline-white {
        background-color: rgba(255, 255, 255, .5);
        border: 3px solid #fff;
        border-radius: .5rem;
        font-size: 25px;
        font-weight: 600;
        color: $color-mine-shaft-grey;
    }

    .type-header {
        display: inline-block;
        font-size: 18px;
        border-bottom: solid 2px $redesign-primary;
    }
}
