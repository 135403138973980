﻿/* ===========================
File Name:      _search.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/2/2021
Produced By:    chris
=========================== */

// The main container
body.search-results-body {
  main.search-results {
    
  }
}

