﻿/* ===========================
File Name:      _components.scss
Produced For:   VersitiClinicalTrials
Produced On:    10/29/2021
Produced By:    chris
=========================== */
// - Simple
@import "simple/button";
@import "simple/header";
@import "simple/image";

// - Card
@import "card/card";

// - Jumbotron / Hero
@import "jumbotron/jumbotron";
@import "hero/hero";

// - Complex
@import "accordion/accordion";
@import "form/form";
@import "menu/header";
@import "menu/footer";