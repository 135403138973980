/* ===========================
File Name:      styles.scss
Produced For:   VersitiClinicalTrials
Produced On:    10/29/2021
Produced By:    chris

The main scss file
=========================== */
/* ===================================
Any custom mixins & configurations
=================================== */
@import "config/color";
@import "config/typography.scss";
@import "config/mixins";
/* ===================================
External libraries
=================================== */
// SEE: https://getbootstrap.com/docs/5.1/getting-started/introduction/
@import "node_modules/bootstrap/scss/bootstrap";
/* ===================================
layout override styles
=================================== */
@import "config/layout";
/* ===================================
Additional component styles
=================================== */
@import "components/components";
/* ===================================
Page-specific Styles
=================================== */
@import "pages/pages";