﻿/* ===========================
File Name:      _header.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/13/2021
Produced By:    chris
=========================== */
div.header-widget-container {
  margin-bottom: -1rem;

  h1,h2,h3,h4,h5 {
    //Is there anything specific to the widget here
  }
}

h1,h2,h3,h4,h5 {
  //Put the gradiant underline in there ... with magic
  &.header-underline {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      @include triple-gradient();
      position: absolute;
      left: 0;
      bottom: -7px;
    }
  }
}

.h404, .main-404 h1 {
    font-size: 120px;
    margin: 90px 0;
    font-weight: 100;
    font-family: Bariol, "Bariol", sans-serif;
}

.main-404 .btn.btn-primary {
    margin-top: 30px;
}