﻿/* ===========================
File Name:      _button.scss
Produced For:   VersitiClinicalTrials
Produced On:    11/11/2021
Produced By:    chris
=========================== */
button {
  &.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
  }
  &.btn-circle.btn-md {
    width: 50px;
    height: 50px;
    padding: 7px 10px;
    border-radius: 25px;
    font-size: 10px;
    text-align: center;
  }
  &.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 12px;
    text-align: center;
  }
}

.btn {
    background-color: white;
    border-radius: 1.25rem;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.btn-outline-primary {
    color: $versiti-ct-orange;
}

.btn-primary {
    background: $versiti-ct-magenta;
    color: white;

    &:hover {
        background: white;
        color: $versiti-ct-magenta;
    }
}