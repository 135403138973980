﻿/* ===========================
File Name:      _footer.scss
Produced For:   VersitiClinicalTrials
Produced On:    11/10/2021
Produced By:    chris
=========================== */
footer {
  width: 100%;

  // --------- Pre-Footer CTA
  section.ct--pre-footer-cta {
    @include triple-gradient;
    color: white;
    padding: 15px 0;
    position: relative;

    &:after {
        content: '';
        opacity: 0.2;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 0;
    }

    > * {
        position: relative;
        z-index: 2;
    }

    .row {
        align-items: center;
    }
  }
  
  // --------- Footer Menu
  section.ct--footer {
    // -- Config section
    background: $versiti-ct-darkgrey;
    color: white;

    // -- Left section
    .ct--footer--footer-nav {
        font-weight: bold;

        .nav-item {
            color: white;
            text-decoration: none;

            &:hover {
                opacity: 0.8;
            }
        }
    }
    .ct--footer--copyright {

    }
    // -- Right section section
    .ct--footer--logo {

    }
    .ct--footer--blurb {
        font-size: 0.65em;
    }
    .ct--footer--social-nav {

        .nav {

        }

        .nav-item {
            color: white;

            + .nav-item {
                margin-left: 5%;
            }
        }
    }
  }  
}

.cookie-cta {
    background: transparentize(white, 0.1);
    display: none;
    -webkit-box-shadow: 0px -5px 15px 5px rgba(0,0,0,0.1);
    box-shadow: 0px -5px 15px 5px rgba(0,0,0,0.1);

    &[data-visible="true"], &.show {
        display: block;
    }
}