﻿/* ===========================
File Name:      _header.scss
Produced For:   VersitiClinicalTrials
Produced On:    11/11/2021
Produced By:    chris
=========================== */
header {
    background: $versiti-ct-offwhite;
    @include bariol;

    .ct--header--top-nav {
        justify-content: center;

        @media(min-width: $breakpoint-md) {
            padding-bottom: 60px !important;
        }

        > .js-search-drawer {
            height: auto !important;
        }

        .navbar-brand {
            color: $redesign-primary;
            font-weight: 600;
            font-size: 25px;
        }

        .btn-round {
            width: 48px;
            height: 48px;
            font-size: 1.2em;
            border-radius: 50%;
            padding: 0;
        }

        .fa-search {
            -webkit-mask: url(/img/search-icon.svg) no-repeat center center;
            mask: url(/img/search-icon.svg) no-repeat center center;
            background-color: $versiti-ct-orange;
            width: 100%;
            height: 100%;

            &:before {
                display: none;
            }

            &:hover {
                background-color: white;
            }
        }

        .fa-bars {
            -webkit-mask: url(/img/menu-icon.svg) no-repeat center center;
            mask: url(/img/menu-icon.svg) no-repeat center center;
            background-color: $versiti-ct-orange;
            width: 100%;
            height: 100%;

            &:before {
                display: none;
            }

            &:hover {
                background-color: white;
            }
        }

        [data-bs-toggle="collapse"]:focus {
            outline: none;
            box-shadow: none;
        }

        [data-bs-target="#navbar-meganav"] {

            &[aria-expanded="true"] {
                .fa-bars {
                    background: none;
                    -webkit-mask: none;
                    mask: none;
                    height: unset;
                    width: unset;

                    &:before {
                        display: none;
                    }

                    &:after {
                        content: "\58";
                    }
                }
            }
        }

        .flex-nowrap {
            white-space: nowrap;
        }
    }

    &[type="search"]::-webkit-search-cancel-button {
        color: $versiti-ct-orange;
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        filter: grayscale(1);
    }
}

#navbar-meganav {
    background: $versiti-ct-offwhite;
    position: absolute;

    .accordion-item {
        background: transparent;
        border: 0 none;
        padding-bottom: 0.5em;
        z-index: 10;
    }

    .menu-item {

        .nav-link {
            color: $versiti-ct-grey7;

            &.nav-link-alt {
                color: $versiti-ct-orange;
            }

            &:hover {
                color: $versiti-ct-magenta;
            }
        }

        .megamenu {
            width: 100%;
            font-size: 12px;

            .megamenu-subhead {
                .nav-link {
                    font-size: 17px !important;
                    font-weight: 700;
                }
            }

            .parent-border {
                border-bottom: 1px solid $versiti-ct-greyd;
            }

            .featured-image {
                display: block;
            }

            @media (min-width: $mobileBreakpoint) {
                &:hover {
                    > .megamenu {
                        display: block;
                    }
                }
            }

            @media (max-width: $mobileBreakpoint) {
                &.show {
                    position: relative;
                    display: block;
                }
            }
        }

        @media (min-width: $mobileBreakpoint) { //desktop only


            .accordion-button {
                display: none;
            }

            &:hover {
                > .megamenu {
                    display: block;
                }
            }
        }
    }

    .accordion-header {
        display: flex;
        font-size: 17px;

        a {
            flex-grow: 1;
        }

        .accordion-button {
            border: 1px solid $versiti-ct-magenta;
            border-radius: 50%;
            color: $versiti-ct-orange;
            background-color: transparent;
            height: 1.75em;
            width: 1.75em;
            padding: 0;

            &:after {
                margin: 0 auto;
            }
        }
    }

    .accordion-header-alt {
        .nav-link {
            color: $versiti-ct-orange;

            &:hover {
                color: $versiti-ct-magenta;
            }
        }
    }

    .accordion-body {

        .nav {
            padding-bottom: 0.5em;

            .nav-link {
                color: $versiti-ct-grey5;
                font-size: 14px;
                line-height: 1.2;

                &:hover {
                    color: $versiti-ct-magenta;
                }
            }
        }
    }

    .search-controls {
        width: 100%;

        .search-toggle {
            background-color: transparent;
            border-color: transparent;

            &:hover {
                background-color: $versiti-ct-magenta;
                border-color: $versiti-ct-magenta;
            }
        }

        .search-drawer {
            width: 100%;

            button {
                border: 0 none;
                color: $versiti-ct-orange;
                background: none;
                width: 48px;
                height: 46px;

                .fa-search {

                    &:hover {
                        background-color: $versiti-ct-orange;
                    }
                }
            }

            input {
                border: 0 none;
                outline: none !important;
                min-width: 45vw;
                text-align: right;
                font-family: "Open Sans", sans-serif;
                background: transparent;

                &:focus {
                    outline: none !important;
                    box-shadow: none;
                }
            }

            &.show {
                right: 0;
                opacity: 1;
                z-index: 20;
                pointer-events: all;
            }

            .search-drawer-btn {
                right: 0;
            }
        }
    }
}

@media (min-width: $mobileBreakpoint) { //desktop only
    .search-controls {
        position: absolute;
    }

    #navbar-meganav {
        display: block;

        .nav-wrapper {
            margin-right: 72px;

            .menu-item {
                .accordion-button {
                    display: none;
                }

                &:hover {
                    > .megamenu {
                        display: block;
                    }
                }

                .megamenu {
                    background: $versiti-ct-concrete-grey;
                    display: none;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .hamburger-menu {
        display: none;
    }

    .search-drawer {
        transition: right, opacity 0.4s !important;
        display: block;
        opacity: 0;
        z-index: 0;
        pointer-events: none;
        background: white;
        top: 50% !important;
        transform: translateY(-50%);
        position: absolute;
        border-radius: 50rem !important;

        .search-bar {
            border-radius: 50rem !important;
            border: 1px solid $versiti-ct-magenta;
            background: white;
        }
    }
}

@media (max-width: $mobileBreakpoint) { //mobile only
    #navbar-meganav {
        overflow-y: scroll;
        max-height: calc(100vh - $menuHeight);

        .featured-image {
            max-width: 60%;
            max-height: 60%;
        }

        .vr {
            display: none;
        }
    }

    .search-controls {
        position: relative;

        .search-toggle {
            display: none;
        }
    }

    .search-drawer {
        background: white;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .search-bar {
            border: 1px solid $versiti-ct-greyd;
            border-radius: 7px;
            outline: none;

            .search-close-btn {
                display: none;
            }

            .search-drawer-btn {
                outline: none;
                right: 15px !important;
                top: 50%;
                transform: translateY(-50%);

                .fa-search {
                    width: 2.5rem;
                    height: 2em;
                    margin-top: 5px;
                }
            }
        }
    }
}