﻿/* ===========================
File Name:      _card.scss
Produced For:   VersitiClinicalTrials
Produced On:    11/24/2021
Produced By:    chris
=========================== */
.row {
  .card {
    padding-left: 0;
    padding-right: 0;

    > img + .card-body {
        padding-top: 1.75rem;
    }
    
    //When a card is inserted as a simplified card, it should not have the normal border around it
    &.simplified-card {
      border: 0;
      border-radius: 0;
      
      .card-body {
          flex-grow: 0;

        h5.card-title {
          font-family: OpenSans, sans-serif;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #2a2a2a;
        }
      }
    }
    
    //Card Image - +background image
    .card-img-top {
      &.card-img-top-background {
        height: 154px;
      }
    }
    
    // Each card can have an infinite number of body tags
    .card-body {

      // ====================================
      // Simple Body Segment
      
      //The title for the segment
      h5.card-title {
        
      }
      
      //The RICH TEXT body for the segment
      div.card-text {
        
      }
      
      // ====================================
      // Image Body Segment
      
      //This is the DIV tag wrapper around the image
      .card-body-img-wrapper {
          height: 147px;
          display: flex;
          align-items: center;
          justify-content: center;

        
        //This is the actual img tag
        .card-body-img {
          max-height: 147px;
          width: auto;
        }
      }


      // ====================================
      // Specialized image body using a image background
      
      .card-body-img-background-wrapper {
        .card-body-img-background {
          height: 147px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    
    
    .card-footer {
      background-color: unset;
      border-top: unset;
      padding: 1rem;
    }
    
  }
}