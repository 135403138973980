﻿@font-face {
    font-family: "Bariol";
    font-style: normal;
    font-weight: 700;
    src: url("/font/bariol/bariol_bold-webfont.ttf?") format("truetype"),
}
@font-face {
    font-family: "Bariol";
    font-style: italic;
    font-weight: 700;
    src: url("/font/bariol/bariol_bold_italic-webfont.ttf?") format("truetype"),
}

@font-face {
    font-family: "Bariol";
    font-style: normal;
    font-weight: 300;
    src: url("/font/bariol/bariol_light-webfont.ttf?") format("truetype"),
}

@font-face {
    font-family: "Bariol";
    font-style: italic;
    font-weight: 300;
    src: url("/font/bariol/bariol_light_italic-webfont.ttf?") format("truetype"),
}

@font-face {
    font-family: "Bariol";
    font-style: normal;
    font-weight: 400;
    src: url("/font/bariol/bariol_regular-webfont.ttf?") format("truetype"),
}

@font-face {
    font-family: "Bariol";
    font-style: italic;
    font-weight: 400;
    src: url("/font/bariol/bariol_regular_italic-webfont.ttf?") format("truetype"),
}

@font-face {
    font-family: "Bariol";
    font-style: normal;
    font-weight: 200;
    src: url("/font/bariol/bariol_thin-webfont.ttf?") format("truetype"),
}

@font-face {
    font-family: "Bariol";
    font-style: italic;
    font-weight: 200;
    src: url("/font/bariol/bariol_thin_italic-webfont.ttf?") format("truetype"),
}