﻿/* ===========================
File Name:      _pages.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/2/2021
Produced By:    chris
=========================== */
body.clinicaltrials {
    // -------------------------------------------
    // All pages get this one
    @import "all/all";
    // -------------------------------------------
    // Kentico Page Types
    // + The default page type
    @import "default/default";
    @import "landing-page";
    // + The faq page type
    @import "faq/faq";
    // -------------------------------------------
    // Special Pages
    // + Search
    @import "search/search";
}
