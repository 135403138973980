﻿body {
    color: $body-color;
    background-color: $body-background-color;
    font-family: "Open Sans", sans-serif;
    font-size: 19px;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    color: $heading-color;
}
//TODO:     Why is this here?
//          Can this be handled in another way? -Chris
.row {
    [class*="col"] {
        img {
            //width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}

@media (min-width: 768px) {
    .container-narrow, .container-narrow.container, .container-narrow.container-md {
        max-width: 920px;
    }
}

.row-cols-lg-4 {
    > .ktc-widget-zone {
        width: 100%;
        display: flex;

        > .ktc-admin-ui {
            order: 9;
            width: 1%;
        }

        > * {
            width: 33.33%;
        }
    }
}

.row-cols-lg-3 {
    > .ktc-widget-zone {
        width: 100%;
        display: flex;

        > .ktc-admin-ui {
            order: 9;
            width: 1%;
        }

        > * {
            width: 33.33%;
        }
    }
}

.row-cols-lg-2 {
    > .ktc-widget-zone {
        width: 100%;
        display: flex;

        > .ktc-admin-ui {
            order: 9;
            width: 1%;
        }

        > * {
            width: 50%;
        }
    }
}

