﻿/* ===========================
File Name:      _jumbotron.scss
Produced For:   VersitiClinicalTrials
Produced On:    11/29/2021
Produced By:    chris
=========================== */
div.jumbotron {
    align-items: center;
    // ==============================================
    // Size (it matters)

    &.jumbotron-small {
        min-height: 255px;
    }

    &.jumbotron-medium {
        min-height: 356px;
    }

    &.jumbotron-large {
        min-height: 500px;
    }

    &.mb-0 {
        margin-bottom: 0 !important;
    }
    // ==============================================
    // How should the background image be handled

    &.jumbotron-background-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    &.vertical-scrim-background {
        background: linear-gradient(
            to bottom,
            rgba(0,0,0,0),
            rgba(0,0,0,0.5)
        );
    }
    
    // ==============================================
    // How should the headlines be displayed?

    h2.jumbotron-headline, h1 {
        font-family: Bariol, "Bariol", sans-serif;
        font-weight: 100;
    }

    h4.jumbotron-subheadline {
        font-size: 19px;
    }
    // ==============================================
    // Any special styling for the jumbotron cta

    .jumbotron-cta {
    }

    .jumbotron-content {
    }

    @media (max-width: $mobileBreakpoint) {
        align-items: flex-end;

        &.jumbotron-small, &.jumbotron-medium, &.jumbotron-large {
            min-height: 350px;
        }

        .jumbotron-content {
            background: transparentize($versiti-ct-magenta, 0.4);
        }
    }
}