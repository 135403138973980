﻿/* ===========================
File Name:      _color.scss
Produced For:   VersitiClinicalTrials
Produced On:    10/29/2021
Produced By:    chris
=========================== */

//Name of the custom color
$versiti-ct-magenta: rgb(203,0,125);
$versiti-ct-lipstick: rgb(201,31,64);
$versiti-ct-golden: rgb(244,196,0);
$versiti-ct-orange: #f47021;
$versiti-ct-darkgrey: #393939;
$versiti-ct-grey2: #2a2a2a;
$versiti-ct-grey3: #343a40;
$versiti-ct-grey5: #595959;
$versiti-ct-grey7: #787878;
$versiti-ct-greyc: #c3c3c3;
$versiti-ct-greyd: #dedede;
$versiti-ct-offwhite: #f8f9fa;
$versiti-ct-concrete-grey: #f3f3f3;

//colors - name generated from https://chir.ag/projects/name-that-color
$color-flame-pea-orange: #df5935;
$color-tango-orange: #f37021;
$color-mine-shaft-grey: #313131;
$color-wild-sand-grey: #f5f5f5;
$color-silver-chalice-grey: #9e9e9e;

//Bootstrap custom color name
$primary: $versiti-ct-magenta;
$secondary: $versiti-ct-orange;
$body-color: $versiti-ct-grey7;
$body-background-color: $color-wild-sand-grey;
$heading-color: $versiti-ct-grey2;

$redesign-primary: $color-flame-pea-orange;

//Theme colors object
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "body": $body-color
);

@mixin triple-gradient {
    background: $versiti-ct-magenta;
    background: linear-gradient(90deg, $versiti-ct-golden -6%, $versiti-ct-lipstick 44%, $versiti-ct-magenta 95%);
}