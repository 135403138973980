﻿/* ===========================
File Name:      _hero.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/2/2021
Produced By:    chris
=========================== */
.ct--hero-section {
    display: inline;
    //I borrowed the jumbotron to show the hero section
    .ct--hero--jumbotron {
    }

    $topBandHeight: 8px;

    .ct--hero-section--title-bar {
        @include triple-gradient();
        top: $menuHeight;
        z-index: 1015;

        &.is-pinned {
            background: $versiti-ct-magenta;

            &:before, &:after {
                opacity: 0;
            }
        }

        &:before {
            content: '';
            @include triple-gradient();
            width: 100%;
            height: $topBandHeight;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: opacity 0.4s;
            opacity: 1;
        }

        &:after {
            content: '';
            opacity: 0.2;
            background: white;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 0;
            transition: opacity 0.4s;
        }

        > * {
            position: relative;
            z-index: 1;

            &:first-child {
                padding-top: $topBandHeight;
            }
        }

        @media (max-width: $mobileBreakpoint) {
            background: transparentize($versiti-ct-magenta, 0.4);
            margin-top: 0;

            &:after, &:before {
                display: none;
            }
        }
        //The logo (to the left of the text) - this may not even be a thing at some point but at one point, it looked like it might
        .ct--hero-section--title-logo {
        }
        //The text in the title bar (left side)
        .ct--hero-section--title-text {
        }
        //Each link in the title bar will have this class (right side)
        .ct--hero-section--title-bar-link {
        }
    }

    @media (max-width: $mobileBreakpoint) {
        display: block;
        position: relative;
        /*.ct--hero-section--title-bar {
            background: transparentize($versiti-ct-magenta, 0.4);
            margin-top: 0;



            &:after, &:before {
                display: none;
            }
        }

        .jumbotron + .ct--hero-section--title-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            top: unset;
        }*/
    }
}