﻿/* ===========================
File Name:      _accordion.scss
Produced For:   VersitiClinicalTrials
Produced On:    12/7/2021
Produced By:    chris
=========================== */

// This class will be in the wrapper container for every accordion
.versiti--accordion-container {
  // This class will be in the div around each accordion item
  .versiti--accordion-item {
    
    
  }
}