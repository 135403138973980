﻿/* ===========================
File Name:      _form.scss
Produced For:   VersitiClinicalTrials
Produced On:    11/30/2021
Produced By:    chris
=========================== */

//All form builder forms will be wrapped in this
div.form-container {
    border: 1px solid $versiti-ct-orange;
    padding-top: 30px;
    padding-bottom: 35px;
    //The submit button on the form.  This is generated by kentico by default and cannot be easily overridden.
    input.form-submit-button {
        background: $versiti-ct-magenta;
        color: white;
        margin-left: 1rem;
    }
    //There can be any number of sections in a given form
    div.versiti--form-section {

        .form-field {
            margin: 10px 0;

            div.field-validation-error {
                color: $versiti-ct-lipstick;
            }

            label {
                font-weight: bold;

                &.control-label {
                    margin-bottom: 5px;
                }
            }
        }

        .ktc-radio-list, .ktc-checkbox-list {
            display: flex;
            flex-wrap: wrap;
        }

        .ktc-checkbox, .ktc-radio-list .ktc-radio {
            display: flex;
            align-items: center;
            position: relative;
            margin: 7px 0;
        }

        .special-form-control {
            &[type="checkbox"] {
                &:checked, &.checked, &[checked="true"], &[checked="checked"] {
                    background-color: $versiti-ct-orange;
                }
            }
        }

        .form-control, .special-form-control {
            border-color: $versiti-ct-orange;
            border-radius: 0;



            &[type="radio"], &[type="checkbox"] {
                width: 22px;
                height: 22px;
                display: inline-block;
                padding: 0;
                flex-shrink: 0;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                + label {
                    margin-left: 13px;
                    position: static;
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }

                &:checked, &.checked, &[checked="true"], &[checked="checked"] {

                    + label {
                        &:before {
                            position: absolute;
                            display: block;
                            font-family: 'Font Awesome 6 Free';
                            font-weight: 900;
                            color: $versiti-ct-orange;
                            left: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 22px;
                            height: 22px;
                            line-height: 22px;
                            border: 0 none;
                            text-align: center;
                            background: none;
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &[type="checkbox"] {

                &:checked, &.checked, &[checked="true"], &[checked="checked"] {

                    + label {
                        &:before {
                            content: "\f00c";
                        }
                    }
                }
            }

            &[type="radio"] {
                border-radius: 50%;

                &:checked, &.checked, &[checked="true"], &[checked="checked"] {

                    + label {
                        &:before {
                            content: "\f192";
                            font-size: 22px;
                        }
                    }
                }
            }
        }
        // /////////////////////////////////////////////
        // Styling for specific areas in the section
        // ALL of our custom sections will have:
        // * An optional headline (H2 as of initial development)
        // * An optional content body (contained within the div mentioned below but not necessarily containing a <p> tag)
        // * One or more columns for adding in form components
        //Styling specifically for the section header row/column
        div.versiti--form-section--header-column {
            //Styling for the actual header element (probably H2)
            .versiti--form-section--header {
                font-size: calc(1rem + 0.9vw);
                margin-bottom: 1.2rem;
            }
        }
        //The area for rich text content to be displayed within the form
        div.versiti--form-section--content-column {
        }
        //The area where the form components will live
        //NOTE: Don't forget that you won't necessarily know the exact structure of the elements found in here
        //        but that's just the nature of form builder ¯\_(ツ)_/¯
        div.versiti--form-section--fields-column {

            .ktc-checkbox-list {
            }
        }
        // /////////////////////////////////////////////
        // Styling for the specific types of sections
        // NOTE: This class will always appear next to `versiti--form-section`
        //In case there's formatting specific to 1-column
        &.versiti--form-section-1 {

            .ktc-checkbox-list .ktc-checkbox, .ktc-radio-list .ktc-radio {
                width: 100%;

                @include media-breakpoint-up(lg) {
                    width: 50%;
                }
            }
        }
        //In case there's formatting specific to 2-column
        &.versiti--form-section-2 {

            .ktc-checkbox-list, .ktc-radio-list {
                flex-direction: column;
            }
        }
    }
}